import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "./firebaseConfig";
import axios from "axios";
import "aos/dist/aos.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u;
  const key = "6LfXXtMnAAAAAAwm1r3zNSHpqL9f1LflAfdDjirq444444444";

  const handleFormSubmit = async (e) => {
    e.preventDefault();



    setIsLoading(true);

    try {
      await axios.post('#', {
        fullName,
        email,
        subject,
        message,
      });

      await addDoc(collection(db, "messages"), {
        fullName,
        email,
        subject,
        message,
      });

      setFullName("");
      setEmail("");
      setSubject("");
      setMessage("");

      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
      }, 5000);

    } catch (error) {
      console.error("Error adding document: ", error);
    }

    setIsLoading(false);
  };

  return (<>
    <hr className="border-gray-900 dark:border-white border-1" />
    <section
      id="contact"
      className="bg-gray-200 dark:bg-gray-700 pt-24 "
    >

      <div className="container">
        <h3 className=" dark:text-primaryColor font-[800] text-4xl mb-8 text-center">
          <span className="border-b-2 border-solid border-secondaryColor dark:border-white">
            Please contact me for more information
      </span>
        </h3>
        <div className="grid lg:grid-cols-4 grid-rows-4 mt-20   lg:mb-0 text-left">
          <p className="flex items-center justify-left text-secondaryColor dark:text-white text-center font-semibold text-lg">
            <span className="text-primaryColor text-3xl"> {/* Adjust the size here */}
              <MdOutlineAlternateEmail />
            </span>
&nbsp;leachouri2019@gmail.com
</p>
          <p className="flex items-center justify-left text-secondaryColor dark:text-white text-center font-semibold text-lg">
            <span className="text-primaryColor text-3xl"> {/* Adjust the size here */}
              <FaWhatsapp />
            </span>
  &nbsp; +212 613891359
</p>

          <p className="flex items-center justify-left text-secondaryColor dark:text-white text-center font-semibold text-lg">
            <span className="text-primaryColor text-3xl"> {/* Adjust the size here */}
              <FaGithub />
            </span>
            <a
              className="hover:cursor-pointer hover:underline hover:text-primaryColor ml-2"
              href="https://github.com/leachouri"
              target="_blank"
              rel="noreferrer"
            >
              Leachouri
        </a>
          </p>  <p className="flex items-center justify-left text-secondaryColor dark:text-white text-center font-semibold text-lg">
            <span className="text-primaryColor text-3xl"> {/* Adjust the size here */}
              <FaLinkedin />
            </span>
            <a
              className="hover:cursor-pointer hover:underline hover:text-primaryColor ml-2"
              href="https://www.linkedin.com/in/mohamed-leachouri"
              target="_blank"
              rel="noreferrer"
            >
              LEACHOURI Mohamed
</a>
          </p><br />
        </div>




      </div>

    </section>
  </>
  );
};

export default Contact;
