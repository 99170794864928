import React from "react";
import "aos/dist/aos.css";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <hr className="border-gray-900 dark:border-white border-1" />
      {/* footer bottom */}
      <div className="dark:bg-gray-700 bg-[rgb(225,228,228)] py-4">
        <div className="container flex justify-center">
          <div className="flex items-center justify-center sm:justify-between">
            <p className="dark:text-gray-100 text-black text-[16px] text-bold text-center">

              <a
                className="hover:text-primaryColor"
                href="https://github.com/leachouri"
                target="_blank"
                rel="noreferrer"
              ><b>Mohamed Leachouri - {" "}
                {year}
                </b>
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* footer bottom */}
    </footer>
  );
};

export default Footer;
