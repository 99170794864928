import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'; 
import "./App.css";
import 'aos/dist/aos.css';
import Aos from 'aos';
import Header from './components/Header/Header.jsx';
import Hero from './components/UI/Hero.jsx';
import Experience from './components/UI/Experience.jsx';
import Skills from './components/UI/Skills.jsx';
import Education from './components/UI/Education.jsx';
import Portfolio from './components/UI/Portfolio';
import Contact from './components/UI/Contact';
import Footer from './components/Footer/Footer';
import ReactGA from 'react-ga';

function App() {
  const isMobile = useMediaQuery({ maxWidth: 640 });

  useEffect(() => {
    document.title = "Mohamed Leachouri | Portfolio"
    Aos.init();
    ReactGA.initialize('G-CR91YKDB4Q');
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);
 

  return (
    <div className='overflow-hidden font-mono'>
        <div className={`min-h-screen ${isMobile ? 'overflow-hidden' : ''}`}>
          <Header />
          <main />
          <Hero />
          <Experience />
          <Skills />
          <Education />
          <Portfolio />
          <Contact />
          <main />
          <Footer />
        </div> 
    </div>
  );
}

export default App;
