import React from "react";
import "aos/dist/aos.css";
import "./style.css"
import { FaLaptopCode } from "react-icons/fa";

const Experience = () => {

 return (<>
  <section
   id="experience"
   className="bg-[rgb(225,228,228)] dark:bg-gray-700 pt-10 pb-10"
  >

   {/* 1. ============= Experience ============= */}

   <div className="container flex justify-center transform transition ease-in duration-500 ">
    <div className="experience-card bg-white dark:bg-[rgb(24,26,27)] w-full md:w-[750px] mt-10 pb-10 px-7 border-2 border-[#2fb8b8] shadow-lg dark:shadow-xl shadow-black flex">

     {/* Vertical Line (Left Side) */}
     <div className="vertical-line mt-4 bg-primaryColor h-full w-1"></div>

     {/* Content Section */}
     <div className="content-section px-7 mt-6">
      {/* Header Section */}
      <h1 className="text-center text-3xl font-semibold dark:text-[rgb(94,191,221)] pb-5">
       <span className="border-b-4 border-primaryColor pb-2">
        EXPERIENCE
    </span>
      </h1>

      {/* Experience Entries */}
      <div className="space-y-8">
       {/* Java Fullstack Consultant */}
       <div className="experience-entry">
        <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400 flex items-center">
         <FaLaptopCode className="mr-2" /> Java Fullstack Consultant
      </h2>
        <p className="text-gray-600 dark:text-gray-300"><b>LINK Ingénierie | Toulouse, France - (Full-Remote)</b></p>
        <p className="text-blue-800 dark:text-blue-300">[ 03/2024 - Today ]</p>
        <ul className="list-disc pl-5 text-gray-600 dark:text-gray-400">
         <li>Developed robust applications using Java, Spring Boot, and ReactJS.</li>
         <li>Designed APIs with Open Feign, Rest API, and Spring Security.</li>
         <li>Deployed applications using Docker, Portainer, and RabbitMQ.</li>
         <li>Utilized Microservices, Eureka, and SonarQube for scalability.</li>
         <li>Collaborated using Jira and Confluence in a Scrum environment.</li>
        </ul>
       </div>

       {/* Web Developer / AI Developer */}
       <div className="experience-entry">
        <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400 flex items-center">
         <FaLaptopCode className="mr-2" /> Web Developer / AI Developer
      </h2>
        <p className="text-gray-600 dark:text-gray-300"><b>AI Automation Agency | Tangier, Morocco - (Hybrid)</b></p>
        <p className="text-blue-800 dark:text-blue-300 ">[ 07/2023 - 01/2024 ]</p>
        <ul className="list-disc pl-5 text-gray-600 dark:text-gray-400">
         <li>Developed web applications using Django, ReactJS, and Redux.</li>
         <li>Implemented NLP and machine learning models.</li>
         <li>Created and deployed chatbots using LLMs and generative AI.</li>
         <li>Integrated AWS services like EC2 and S3 for data hosting.</li>
        </ul>
       </div>

       {/* Java Web Developer */}
       <div className="experience-entry">
        <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400 flex items-center">
         <FaLaptopCode className="mr-2" /> Java Web Developer
      </h2>
        <p className="text-gray-600 dark:text-gray-300"><b>SoftOnline | Meknes, Morocco - (On-Site)</b></p>
        <p className="text-blue-800 dark:text-blue-300">[ 04/2021 - 09/2021 ]</p>
        <ul className="list-disc pl-5 text-gray-600 dark:text-gray-400">
         <li>Developed web-based aspiration modules for public procurement.</li>
         <li>Worked on monitoring tools and web scraping projects.</li>
        </ul>
       </div>

       {/* Full-Stack Java Web Developer */}
       <div className="experience-entry">
        <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400 flex items-center">
         <FaLaptopCode className="mr-2" /> Full-Stack Java Web Developer
      </h2>
        <p className="text-gray-600 dark:text-gray-300"><b>FREELANCE - (Full-Remote)</b></p>
        <p className="text-blue-800 dark:text-blue-300">[ 09/2020 - 04/2021 ]</p>
        <ul className="list-disc pl-5 text-gray-600 dark:text-gray-400">
         <li>Backend development using Java, Spring Boot, and Spring Security.</li>
         <li>Frontend development with Angular, ReactJS, and Redux.</li>
         <li>Implemented JWT for secure authentication.</li>
        </ul>
       </div>
      </div>
     </div>
    </div>
   </div>


  </section>
 </>
 );
};

export default Experience;
