import React from "react";
import "remixicon/fonts/remixicon.css";
import "aos/dist/aos.css";
import "../../styles/hero.css";
import PDF2 from "../../assets/images/CV_Dev_Java Mohamed Leachouri.pdf";
import PDF3 from "../../assets/images/CV_Dev_Java Mohamed Leachouri-fr.pdf";

import Img from "../../assets/images/lea000.JPG";

const Hero = () => {
  return (
    <section id="home" className="bg-[rgb(225,228,228)] py-20 font-mono 
        text-black dark:text-white dark:bg-gray-700 pt-0">
      <div className="container pt-14">
        <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
          {/* hero left start */}
          <div className="w-full  md:w-1/2">

            <h1 
              className="text-headingColor font-[800] text-[1.8rem] 
              sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5"
            >
              I'm Mohamed LEACHOURI, <br />
              <span className="text-xl ">
                <span className="border-b border-solid border-primaryColor dark:border-white">Java Fullstack Web Developer</span>

              </span>
            </h1>

            <div className="flex items-center gap-6 mt-7">


              <a
                href={PDF2}
                className="inline-block px-4 py-2 bg-blue-300 text-gray-800  
                font-semibold rounded-lg shadow-md hover:bg-blue-400 
                transition-colors duration-300 hover:scale-105" 
                target="_blank"
                rel="noopener noreferrer"
              >
                My Resume (English)
            </a>
              <a
                href={PDF3}
                className="inline-block px-4 py-2 bg-blue-300 text-gray-800  hover:scale-105
                font-semibold rounded-lg shadow-md hover:bg-blue-400 transition-colors duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                My Resume (French)
            </a>


            </div>

            <div className="flex max-w-2xl gap-2 border-2 p-2 
                          border-gray-500 mt-12 font-mono text-black
                          dark:bg-gray-400 rounded-2xl
                          text-[18px] sm:pr-10">
          I am a Java Fullstack Web Developer with nearly 3 years of experience, 
          specializing in Java, Spring, React, Angular, Microservices, 
          and AI development. 
          Additionally, I have a strong background in DevOps practices.
            </div>

            <div className="flex items-center gap-9 mt-14">
              <span className="text-smallTextColor dark:text-white 
                                text-[15px] font-[500]">
                Find me here:
              </span>
              <span className="animate-bounce">
                <a
                  title="LinkedIn"
                  href="https://www.linkedin.com/in/mohamed-leachouri"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-gray-700 text-white p-1 rounded-xl
                  dark:text-blue-900 dark:bg-zinc-300 text-[20px] font-[500]"
                >
                  <i className="ri-linkedin-line"></i>
                </a>
              </span>
              <span className="animate-bounce">
                <a
                  title="GitHub"
                  href="https://github.com/leachouri"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-gray-700 p-1 rounded-xl
                  dark:text-blue-900 dark:bg-zinc-300 text-[20px] font-[500]"
                >
                  <i className="ri-github-fill"></i>
                </a>
              </span>
            </div>
          </div>
          {/* Hero left end */}
          {/* Hero img */}
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            className="md:basis-1/2 flex sm:ml-0 text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col justify-center md:text-end pl-20"
          >
            <figure className="flex items-center justify-center">
              {/* <img 
                src={heroGif} 
                alt="Man Walking" 
                className="mr-20 lg:mr-0 w-72 h-72" 
              /> */}
              <img
                className="rounded-3xl cursor-pointer w-72 h-100 
                hover:scale-110 transition duration-300 ease-in-out pt-1 px-1"
                src={Img}
                alt=""
              />
            </figure>

          </div>
          {/* Hero img */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
